<template>
  <header>
    <h2>weather-app</h2>
    <div class="searchbar">
      <input v-model="city" @keyup.enter="getData()" />
    </div>
    <img alt="Vue logo" class="logo" src="./assets/logo.png" />
  </header>

  <div class="weather-app" v-if="weather.name">
    <h1 class="location">{{ weather.name }}</h1>
    <div class="container">
      <div class="box">
        <div>
          {{ Math.round(weather.main.temp_min) }}°C -
          {{ Math.round(weather.main.temp_max) }}°C
        </div>
        <h1 class="temperature">{{ Math.round(weather.main.temp) }}°C</h1>
      </div>
      <div class="box">
        <div class="pressure">{{ weather.main.pressure }} hPa</div>
        <h1 v-if="weather.main.pressure > 1026">H</h1>
        <h1 v-else-if="weather.main.pressure < 1000">T</h1>
        <h1 v-else>-</h1>
      </div>
      <div class="description box">
        <div class="clouds">{{ weather.weather[0].description }}</div>
        <img :src="getWeatherIcon(weather.weather[0].icon)" />
      </div>

      <div class="wind box">
        <div>
          {{ formatWind(weather.wind.deg) }}°{{ mps2kts(weather.wind.speed) }}KT
        </div>
        <img
          :style="rotateAccordingWind()"
          class="arrow"
          alt="arrow"
          src="./assets/arrow.png"
        />
      </div>
      <div class="container" v-if="showMore">
        <div class="sunrise box">
          <div>{{ timeConverter(weather.sys.sunrise).substr(-8) }}</div>
          <div>Sunrise</div>
        </div>
        <div class="sunset box">
          <div>{{ timeConverter(weather.sys.sunset).substr(-8) }}</div>
          <div>Sunset</div>
        </div>
        <div class="visibility box">
          <div>{{ weather.visibility }} m</div>
          <div>Visibility</div>
        </div>
        <button @click="showMore = !showMore"><b>show less</b></button>
      </div>
      <div class="container" v-else>
        <button @click="showMore = !showMore"><b>show more</b></button>
      </div>
    </div>
  </div>
  <footer>
    <!---<div class="date">Last updated: {{ timeConverter(weather.dt) }}</div>--->
    <div class="date">{{ Date(weather.dt).substr(0, 24) }}</div>
  </footer>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {},
  data() {
    return {
      api_key: "de97c5561397009a371b80f83b7ca053",
      url_base: "https://api.openweathermap.org/data/2.5/",
      city: "Winterthur",
      weather: {},
      rotation: 0,
      showMore: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      fetch(
        `${this.url_base}weather?q=${this.city}&units=metric&appid=${this.api_key}&lang=en`
      )
        .then((response) => {
          return response.json();
        })
        .then(this.setResults);
    },
    setResults(results) {
      this.weather = results;
      console.log(this.weather);
    },
    rotateAccordingWind() {
      let rotation = 90 + this.weather.wind.deg;
      return "transform: rotate(+" + rotation + "deg)";
    },
    mps2kts(mps) {
      let kts = "0" + Math.round(mps * 1.94384);
      return kts.substr(-2);
    },
    timeConverter(unix_timestamp) {
      let date = new Date(unix_timestamp * 1000);
      let year = date.getFullYear();
      let month = "0" + date.getMonth();
      let day = "0" + date.getDay();
      let hours = "0" + date.getHours();
      let minutes = "0" + date.getMinutes();
      let seconds = "0" + date.getSeconds();
      let formattedTime =
        day.substr(-2) +
        "." +
        month.substr(-2) +
        "." +
        year +
        " " +
        hours.substr(-2) +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2);
      return formattedTime;
    },
    formatWind(wind) {
      let formattedWind = "00" + wind;
      return formattedWind.substr(-3);
    },
    getWeatherIcon(icon) {
      let link = "http://openweathermap.org/img/wn/" + icon + "@2x.png";
      return link;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.arrow {
  padding: 30px;
  width: 50px;
}
.box {
  padding-top: 10px;
  border: 1px solid black;
  width: 150px;
  height: 120px;
  margin: 10px;
  box-shadow: 0 0 20px #ccc;
}
.container {
  width: 350px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
button {
  margin: 32px auto;
  border-radius: 25px;
  background: #42b883;
  width: 200px;
  height: 50px;
  border: none;
  box-shadow: 0 0 20px #ccc;
}
button:hover {
  background: white;
  border: 2px solid #42b883;
  transition: background-color 0.3s ease-in;
}
footer {
  margin-top: 32px;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: 1px solid;
}
h2 {
  padding-top: 12px;
  margin: auto auto auto 32px;
}
.logo {
  padding-top: 4px;
  height: 60px;
  width: 60px;
  margin: 24px;
}
.searchbar {
  position: absolute;
  top: 32px;
  right: 0;
  padding: auto;
  width: 100%;
  height: 32px;
}
input {
  max-width: 350px;
  height: 48px;
  padding-left: 50px;
  font-size: 18px;
  border-radius: 25px;
  border: 1px solid grey;
  background-image: url("./assets/search.png");
  background-position: 14px 14px;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}
.weather-app {
  margin-top: 200px;
}
</style>
